<template>
  <div id="setting">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />编辑
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onUpdateClick"
        >
          完成
        </van-col>
      </van-row>
      <div class="setting-container">
        <div class="setting-body">
          <van-cell center :border="true" value="头像" size="large" is-link>
            <template #right-icon>
              <van-uploader
                max-count="1"
                
                accept="image/*"
                :after-read="fnUploadRequest"
              >
                <van-image width="40" height="40" round :src="user.avatar" />
              </van-uploader>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="用户名称" size="large" is-link>
            <template #right-icon>
              <input
                class="cell-input"
                v-model="user.username"
                type="text"
                placeholder="请输入用户名称"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="登录手机号" size="large" style="color:#969799;" is-link>
            <template #right-icon>
              <!-- <span>{{mobile}}</span> -->
                <span style="color:#969799;">{{mobile}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
            <van-cell center :border="true"  :clickable="false" value="修改密码" size="large" to="/profile/update-password" is-link>
            
            <template #right-icon>
              
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            @click="onSubUsersClick"
            :clickable="false"
            center
            :border="true"
            value="管理子账号"
            size="large"
            is-link
          >
            <template #right-icon>
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell
               :clickable="false"
              center
              :border="true"
              value="地区"
              size="large"
              is-link
              style="color:#969799;"
            >
              <template #right-icon>
                <span style="color:#969799;">{{province_name+'/'+city_name+'/'+district_name}}</span>
                <van-icon style="color:#969799;" name="arrow" />
              </template>
            </van-cell>
            <van-cell center :border="true"  :clickable="false" value="营业执照" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <!-- <input
                class="cell-input"
                v-model="company.license_img"
                type="text"
                placeholder="请输入内容"
              /> -->
               <van-uploader
                max-count="1"
                :readonly="status=='1'?true:false"
                :disabled="status=='1'?true:false"
                accept="image/*"
                :after-read="fnUploadRequest2"
              >
                <van-image width="60" height="60"  :src="company.license_img" />
              </van-uploader>
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="成立日期" size="large"  v-if="status=='1'"  style="color:#969799;">
            <template #right-icon>
              <!-- <input
                class="cell-input"
                v-model="company.establish_date "
                type="text"
                placeholder="请输入内容"
              /> -->
               <span style="color:#969799;">{{company.establish_date}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="成立日期" size="large"   v-if="status!='1'"  >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="company.establish_date "
                type="text"
                placeholder="请输入内容"
              />
               <!-- <span style="color:#969799;">{{company.establish_date}}</span> -->
              <van-icon name="arrow" />
            </template>
          </van-cell>

           <van-cell center :border="true"  :clickable="false" value="营业截止日期" size="large"  v-if="status=='1'" style="color:#969799;">
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <!-- <input
                class="cell-input"
                v-model="company.valid_period "
                type="text"
                placeholder="请输入内容"
              /> -->
              <span style="color:#969799;">{{company.valid_period}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
             <van-cell center :border="true"  :clickable="false" value="营业截止日期" size="large"  v-if="status!='1'" >
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.valid_period "
                type="text"
                placeholder="请输入内容"
              />
              <!-- <span style="color:#969799;">{{company.valid_period}}</span> -->
              <van-icon name="arrow" />
            </template>
          </van-cell>
         
          <van-cell center :border="true"  :clickable="false" value="公司名称" size="large" v-if="status=='1'"  style="color:#969799;">
            <template #right-icon>
              <!-- <input
                
                class="cell-input"
                v-model="company.name"
                type="text"
                placeholder="请输入内容"
              /> -->
                <span style="color:#969799;">{{company.name}}</span>
                <van-icon  name="arrow" />
              <!-- <van-icon name="arrow" /> -->
            </template>
          </van-cell>
            <van-cell center :border="true"  :clickable="false" value="公司名称" size="large" v-if="status!='1'" >
            <template #right-icon>
              <input
                
                class="cell-input"
                v-model="company.name"
                type="text"
                placeholder="请输入内容"
              />
                <!-- <span style="color:#969799;">{{company.name}}</span> -->
                <van-icon  name="arrow" />
              <!-- <van-icon name="arrow" /> -->
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="英文名称" size="large" is-link>
            <template #right-icon>
              <input
                class="cell-input"
                v-model="company.name_en"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>

          <van-cell center :border="true"  :clickable="false" value="地址" size="large" v-if="status=='1'"  style="color:#969799;">
            <template #right-icon>
              <!-- <input
                class="cell-input"
                v-model="company.address"
                type="text"
                placeholder="请输入内容"
              /> -->
              <span style="display:inline-block;color:#969799;width:60%;overflow:hidden;text-align:right;">{{company.address}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="地址" size="large" v-if="status!='1'"  >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="company.address"
                type="text"
                placeholder="请输入内容"
              />
              <!-- <span style="display:inline-block;color:#969799;width:60%;overflow:hidden;text-align:right;">{{company.address}}</span> -->
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="法人" size="large" v-if="status=='1'" style="color:#969799;">
            <template #right-icon>
              <!-- <input
                class="cell-input"
                v-model="company.legal_person"
                type="text"
                placeholder="请输入内容"
              /> -->
              <span style="color:#969799;">{{company.legal_person}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="法人" size="large" v-if="status!='1'" >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="company.legal_person"
                type="text"
                placeholder="请输入内容"
              />
              <!-- <span style="color:#969799;">{{company.legal_person}}</span> -->
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="法人电话" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.telephone"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            center
            :clickable="false"
            :border="true"
            value="社会信用代码"
            size="large"
            v-if="status=='1'"
             style="color:#969799;"
          >
            <template #right-icon>
              <!-- <span>{{ company.social_credit_card }}</span> -->
              <!-- <input
                class="cell-input"
                
                v-model="company.social_credit_card" 
                type="text"
                placeholder="请输入内容"
              /> -->
               <span style="color:#969799;">{{company.social_credit_card}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell
            center
            :clickable="false"
            :border="true"
            value="社会信用代码"
            size="large"
            v-if="status!='1'"
            
          >
            <template #right-icon>
              <!-- <span>{{ company.social_credit_card }}</span> -->
              <input
                class="cell-input"
                
                v-model="company.social_credit_card" 
                type="text"
                placeholder="请输入内容"
              />
               <!-- <span style="color:#969799;">{{company.social_credit_card}}</span> -->
              <van-icon name="arrow" />
            </template>
          </van-cell>
          
          <van-cell center :border="true"  :clickable="false" value="许可文号" size="large" v-if="status!='1'">
            <template #right-icon>
              <!-- <span>{{ company.license_file_no }}</span> -->
              <input
                
                class="cell-input"
                v-model="company.license_file_no"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="许可文号" size="large" v-if="status=='1'" style="color:#969799;">
            <template #right-icon>
              <span style="display:inline-block;color:#969799;width:60%;overflow:hidden;text-align:right;">{{company.license_file_no}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="许可证号" size="large"  v-if="status!='1'">
            <template #right-icon>
             
              <input
                class="cell-input"
                v-model="company.license_no"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="许可证号" size="large" v-if="status=='1'" style="color:#969799;">
            <template #right-icon>
              <span style="display:inline-block;color:#969799;width:60%;overflow:hidden;text-align:right;">{{company.license_no}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="发证单位" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.unit }}</span> -->
              <input
                class="cell-input"
                v-model="company.unit"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="传真" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.fax }}</span> -->
              <input
                class="cell-input"
                v-model="company.fax"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="联系人" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.contact_person"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="联系电话" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.contact_mobile"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="质保金额(万元)" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.deposit"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell center :border="true"  :clickable="false" value="质保银行" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <input
                class="cell-input"
                v-model="company.deposit_bank"
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <van-cell center :border="true"  :clickable="false" value="质保凭条" size="large" is-link>
            <template #right-icon>
              <!-- <span>{{ company.telephone }}</span> -->
              <!-- <input
                class="cell-input"
                v-model="company.deposit_receipt"
                type="text"
                placeholder="请输入内容"
              /> -->
              <van-uploader
                max-count="1"
                
                accept="image/*"
                :after-read="fnUploadRequest1"
              >
                <van-image width="60" height="60"  :src="company.deposit_receipt" />
              </van-uploader>
              <van-icon name="arrow" />
            </template>
          </van-cell>
           <!-- <van-cell center :border="true"  :clickable="false" value="成立日期" size="large" is-link>
            <template #right-icon>
            
               <span style="color:#969799;">{{company.social_credit_card}}</span>
              <van-icon name="arrow" />
            </template>
          </van-cell> -->
           <!-- <van-cell center :border="true"  :clickable="false" value="营业截止日期" size="large" is-link>
            <template #right-icon>
              <input
                class="cell-input"
                v-model="company.valid_period "
                type="text"
                placeholder="请输入内容"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell> -->
        
          <div class="btn" @click="onUpdateClick">确认修改</div>
        </div>
      </div>
    </div>
    <!-- <van-dialog v-model="show" title="编辑" show-cancel-button >
      <input style="height:1rem;line-height:1rem;padding:0 10px;" type="text" placeholder="请输入内容">
    </van-dialog> -->
  </div>
</template>

<script>
import cookies from "../../utils/cookies";
import oss from "@/utils/oss";
import { Toast } from "vant";
export default {
  name: "setting-item",
  data() {
    return {
      status_text: ["营业中"],
      // user: JSON.parse(cookies.get("user")),
      user: {
        avatar: "",
        // password:null
      },
      mobile:'',
      // repassword:null,
      show: true,
      province_name:"",
      city_name:"",
      district_name:"",
      commit_type:1,
      status:"",
      company: {
        name: "",
        name_en: "", 
        address: "",
        license_no: "", //许可证号
        unit: "", //发证单位
        license_file_no: "", //许可文号
        legal_person: "",
        place_of_business: "", //经营场所
        // area_code: "",
        telephone: "", //联系方式
        fax: "", //传真
        social_credit_card: "",
        license_img:'',//营业执照
        deposit:'',//质保金
        deposit_bank:'',//质保银行
        deposit_receipt:'',//质保凭条照片
        contact_person:"",//联系人
        contact_mobile:"",//联系电话
        establish_date:"",//成立日期
        valid_period:""//有效期期限
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubUsersClick() {
      if(this.status=='0'){
        Toast("您的账号还未审核，暂无操作权限！");
        return false;
      }
      this.$router.push("/profile/sub-users");
    },
    async get_current_company() {
      const res = await this.$apis.get_current_company();
      this.status = res.data.company.status
      this.user = res.data.user;
      this.company.name =res.data.company.name 
      this.company.name_en= res.data.company.name_en,
      this.company.address = res.data.company.address
      this.company.license_no = res.data.company.license_no //许可证号
      this.company.unit = res.data.company.unit //发证单位
      this.company.license_file_no = res.data.company.license_file_no //许可文号
      this.company.legal_person = res.data.company.legal_person
      this.company.place_of_business = res.data.company.place_of_business //经营场所

      this.company.telephone = res.data.company.telephone //联系方式
      this.company.fax = res.data.company.fax //传真
      this.company.social_credit_card = res.data.company.social_credit_card
      this.company.business_term = res.data.company.business_term
      this.company.license_img= res.data.company.license_img
      this.company.deposit = res.data.company.deposit//质保金
      this.company.deposit_bank =  res.data.company.deposit_bank//质保银行
      this.company.deposit_receipt = res.data.company.deposit_receipt//质保凭条照片
      this.company.contact_person = res.data.company.contact_person//联系人
      this.company.contact_mobile = res.data.company.contact_mobile//联系电话
      this.company.establish_date = res.data.company.establish_date//成立日期
      this.company.valid_period = res.data.company.valid_period//成立日期
      this.province_name = res.data.company.province_name
      this.city_name = res.data.company.city_name
      this.district_name = res.data.company.district_name
      this.mobile = res.data.user.mobile
      this.commit_type = res.data.company.status==2?2:1
      this.status = res.data.company.status
    },
     async ocr_license_img(){
       
        const license_img_url = this.company.license_img
        if(license_img_url){
           Toast.loading({
            message: "识别中...",
            forbidClick: true,
          });
          const res = await this.$apis.ocr_license_img(license_img_url);
          if(res.data.success){
            // this.is_ocr_license_img = true
            this.company.address = res.data.address
            this.company.name = res.data.name
            this.company.legal_person = res.data.person
            this.company.social_credit_card = res.data.reg_num
            this.company.establish_date = res.data.establish_date
            this.company.valid_period = res.data.valid_period=='2999-12-31'?'长期':res.data.valid_period
            Toast.clear();
          }else{
            Toast("您上传的营业执照无法识别，请重新上传");
            return false
          }
          
      }else{
        Toast("请上传营业执照");
        return false
      }
      
    },
    async onUpdateClick() {
     
      const _self = this;
      if(!this.user.username){
        Toast('请填写用户名称')
        return false
      }
      if(this.user.username.length>20){
        Toast('用户名称太长')
        return false
      }
      if(this.company.name.length>40){
        Toast('企业名称太长')
        return false
      }
      if(!this.company.name){
        Toast('请填写企业名称')
        return false
      }
       if(this.company.legal_person.length>10){
        Toast('法人名称太长')
        return false
      }
       
      if(!this.company.social_credit_card){
        Toast('请输入统一信用代码')
        return false
      }
      if (this.company.social_credit_card) {
        if (!this.CheckSocialCreditCode(this.company.social_credit_card)) {
          return false;
        }
      }
      if(!this.company.legal_person){
        Toast('请输入法人名称')
        return false
      }
      if(this.company.legal_person){
        if(!this.validFF(this.company.legal_person)){
          Toast('法人名称包含非法字符')
          return false
        }
      }
      if (!this.company.telephone) {
        Toast('请输入法人手机号')
        return false
      }
      if (this.company.telephone) {
        if (!this.validatemobilePublic(this.company.telephone)) {
          Toast("请输入正确的法人手机号");
          return false;
        }
      } 

      if(this.company.fax){
        if(!this.isTel(this.company.fax)){
          Toast('请输入正确的传真')
          return false
        }
      }
      if(!this.company.license_file_no){
         Toast('请输入许可文号')
          return false
      }
      if(this.company.license_file_no){
        if(!this.validFF(this.company.license_file_no)){
          Toast('许可文号包含非法字符')
          return false
        }
      }
      if(!this.company.license_no){
        Toast('请输入许可证号')
        return false
      }
      if(this.company.license_no){
        if(!this.validFF(this.company.license_no)){
          Toast('许可证号包含非法字符')
          return false
        }
        if(!this.vaildZH(this.company.license_no)){
          Toast('许可证号不能有中文')
          return false
        }
      }
      if (!this.company.contact_mobile) {
        Toast('请输入联系电话')
        return false
      }
      if (this.company.contact_mobile) {
        if (!this.validatemobilePublic(this.company.contact_mobile)) {
          Toast("请输入正确的联系电话");
          return false;
        }
      } 
     if(!this.company.deposit){
        Toast('请填写质保金')
        return false
      }
      if(this.myIsNaN(this.company.deposit)){
        Toast('请填写正确的质保金')
        return false
      }
      if(this.company.deposit<=0){
        Toast('质保金不能小于或者等于0')
        return false
      }
      if(this.company.deposit){
      if(this.company.deposit[0]=='.'){
         Toast('质保金输入有误')
          return false
        }
       
      }
     
      if(this.company.deposit){
        if(this.company.deposit[this.company.deposit.length-1]=='.'){
         Toast('质保金输入有误')
          return false
        }
        if(((this.company.deposit.split('.')).length-1)>1){
          Toast('质保金输入有误')
          return false
        }
       
      }
      if(this.company.deposit){
       if(!this.validFF(this.company.deposit)){
          Toast('质保金包含非法字符')
          return false
        }
      }
      if(!this.company.deposit_bank){
        Toast('请填写质保银行')
        return false
      }
      if(this.company.deposit_bank){
       if(!this.validFF(this.company.deposit_bank)){
          Toast('质保银行包含非法字符')
          return false
        }
      }
      if(!this.company.deposit_receipt){
        Toast('请上传质保凭条照片')
        return false
      }
       if(!this.company.contact_person){
        Toast('请填写联系人')
        return false
      }
      if(this.company.contact_person){
       if(!this.validFF(this.company.contact_person)){
          Toast('联系人包含非法字符')
          return false
        }
      }
      

      if(!this.company.license_img){
        Toast('请上传营业执照')
        return false
      }

      if(!this.company.establish_date){
        Toast('请填写成立日期')
        return false
      }

       if(!this.company.valid_period){
        Toast('请填写营业截止日期')
        return false
      }
      
      
      const res = await this.$apis.update_current_company({
        user: this.user,
        company: this.company,
        commit_type:this.commit_type
      });
      if (res.status == 200) {
      if(this.commit_type==2){
        Toast.success("已重新提交，请等待审核通过！");
      }else{
        Toast.success("修改成功！");
      }
        
        this.get_current_company();
        this.$router.go(-1);
      }
    },
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.user.avatar = res.fileUrl[0].split("?")[0];
        Toast.clear();
      });
    },
    fnUploadRequest1(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.company.deposit_receipt = res.fileUrl[0].split("?")[0];
        Toast.clear();
      });
    },
    fnUploadRequest2(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.company.license_img = res.fileUrl[0].split("?")[0];
        this.ocr_license_img()
        Toast.clear();
      });
    },
    //判断中文
    vaildZH(str){
      let regAccountCN = /[\u4e00-\u9fa5]+/g
      if (regAccountCN.test(str)) {
      　　
      　　return false

      }else{
        return true
      }
    },
    //判断非法字符
    validFF(str){
      let regAccount = /[@#\$%\^&\*\s+]+/g
        if (regAccount.test(str)) {
        　
        　　return false
        }else{
          return true
        }
    },
        //判断社会信用代码
    CheckSocialCreditCode(Code) {
      var patrn = /^[0-9A-Z]+$/;
      //18位校验及大写校验
      if (Code.length != 18 || patrn.test(Code) == false) {
        Toast("不是有效的统一社会信用代码");
        return false;
      } else {
        var Ancode; //统一社会信用代码的每一个值
        var Ancodevalue; //统一社会信用代码每一个值的权重
        var total = 0;
        var weightedfactors = [
          1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28,
        ]; //加权因子
        var str = "0123456789ABCDEFGHJKLMNPQRTUWXY";
        //不用I、O、S、V、Z
        for (var i = 0; i < Code.length - 1; i++) {
          Ancode = Code.substring(i, i + 1);
          Ancodevalue = str.indexOf(Ancode);
          total = total + Ancodevalue * weightedfactors[i];
          //权重与加权因子相乘之和
        }
        var logiccheckcode = 31 - (total % 31);
        if (logiccheckcode == 31) {
          logiccheckcode = 0;
        }
        var Str =
          "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
        var Array_Str = Str.split(",");
        logiccheckcode = Array_Str[logiccheckcode];

        var checkcode = Code.substring(17, 18);
        if (logiccheckcode != checkcode) {
          Toast("不是有效的统一社会信用代码");
          return false;
        } else {
          console.info("yes");
        }
        return true;
      }
    },
    //  判断传真
    isTel(s) {
      //国家代码(2到3位)-区号(2到3位)-电话号码(7到8位)-分机号(3位)"
      // var pattern = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
      //var pattern =/(^[0-9]{3,4}\-[0-9]{7,8}$)|(^[0-9]{7,8}$)|(^\([0-9]{3,4}\)[0-9]{3,8}$)|(^0{0,1}13[0-9]{9}$)/;
      var pattern = /^(\d{3,4}-)?\d{7,8}$/
      
        if (!pattern.exec(s)) {
         
          return false
        }else{
          return true
      }
    },
    myIsNaN(value) {
      return typeof value === 'number' && !isNaN(value);
    },
    validatemobilePublic(mobile) {
      if (!/^1[345678]\d{9}$/.test(mobile)) {
        // Toast("请输入正确的手机号");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
  },
  components: {},
  created() {
    this.get_current_company();
  },
};
</script>

<style scoped>
#setting {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top:10vh;
  /* overflow-y: scroll; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.setting-container {
}
.setting-body {
  background: #ffff;
  height: 89vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  /* padding: 0.3rem; */
  overflow-y: scroll;
  /* overflow-y: scroll; */
  padding-bottom: 5rem;
}
.setting-item {
  height: 1rem;
  line-height: 1rem;
}
.setting-item-title {
  color: #333;
  font-size: 0.3rem;
  text-align: left;
}
.setting-item-value {
  color: #333;
  font-size: 0.3rem;
  text-align: right;
}
.cell-input {
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 5px;
  padding-left: 0;
  text-align: right;
  width:60%
}
.btn{
  height: .8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: .8rem;
  margin-top:.4rem;
  font-size: .3rem;
  border-radius: 5px;
}
</style>
